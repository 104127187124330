
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-widget-12",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      filters: {
        string: "",
      },
      perPage: 10,
      entities: null,
      lastpage: 1,
      stringFilter: "",
      page: 1,
      pages: [],
      count: null,
    };
  },
  methods: {
    triggerSearch(instant) {
      const timeout = setTimeout(
        () => {
          this.filters.string = this.stringFilter;
          this.page = 1;
          this.retrieveData();
        },
        instant ? 10 : 400
      );
    },
    gotoPage(pageNumber) {
      this.page = pageNumber;
      this.retrieveData();
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
      }
      this.retrieveData();
    },
    nextPage() {
      if (this.page < this.lastpage) {
        this.page++;
      }
      this.retrieveData();
    },

    retrieveData() {
      ApiService.query("transports", {
        params: {
          perPage: "99999",
          page: this.page,
          filterString: this.filters.string,
        },
      }).then((response) => {
        this.entities = response.data.data;
        this.count = response.data.total;
        this.lastpage = response.data.last_page;
        this.pages = [];

        /*
        for (let i = -3; i < 3; i++) {
            if(( this.page + i) > 0 && ( this.page + i) <= this.lastpage) {
              this.pages.push(this.page + i);
            }
        }*/
      });
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  mounted() {
    this.retrieveData();
  },
});
